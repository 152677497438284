import { BookNames } from "../../enums";

export default {
    ChooseBook: "Tryk på en bog for at se om den er kompatibel med din telefon",
    [BookNames.Firemaster]: BookNames.Firemaster,
    [BookNames.ArchmageTower]: BookNames.ArchmageTower,
    [BookNames.GloomDragon]: BookNames.GloomDragon,
    [BookNames.WonderSword]: BookNames.WonderSword,
    [BookNames.Outtatown]: BookNames.Outtatown,
    [BookNames.ColouringBook]: BookNames.ColouringBook,
    Back: "Tilbage",
    YourDevice: "Din enhed",
    DeviceSupported: "Din enhed er understøttet",
    DeviceNotSupported: "Din enhed er ikke understøttet",
    DownloadAppText: "Download appen til din telefon her",
    NotFound: "Ikke fundet"
}