import { BookNames, BookNamesEN } from "../../enums";

export default {
    ChooseBook: "Choose a book, to see if it is compatible with your phone",
    [BookNames.Firemaster]: BookNamesEN.Firemaster,
    [BookNames.ArchmageTower]: BookNamesEN.ArchmageTower,
    [BookNames.GloomDragon]: BookNamesEN.GloomDragon,
    [BookNames.WonderSword]: BookNamesEN.WonderSword,
    [BookNames.Outtatown]: BookNamesEN.Outtatown,
    [BookNames.ColouringBook]: BookNamesEN.ColouringBook,
    Back: "Back",
    YourDevice: "Your device",
    DeviceSupported: "Your device is supported",
    DeviceNotSupported: "Your device is not supported",
    DownloadAppText: "Download the app for your phone here",
    NotFound: "Not found"

}