import React from "react"
import {
    LinkingOptions,
    NavigationContainer, DarkTheme as NavigationDarkTheme,
    DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { DeviceCheck, HomePage } from "./src";
import { PaperProvider } from "react-native-paper";
import { MD3DarkTheme, MD3LightTheme, adaptNavigationTheme } from 'react-native-paper';
import merge from 'deepmerge';
import { SafeAreaProvider } from "react-native-safe-area-context";
import { RootStackParamList } from "./src/RootStackParamList";

const { LightTheme, DarkTheme } = adaptNavigationTheme({
    reactNavigationLight: NavigationDefaultTheme,
    reactNavigationDark: NavigationDarkTheme,
});
const CombinedDefaultTheme = merge(MD3LightTheme, LightTheme);
const CombinedDarkTheme = merge(MD3DarkTheme, DarkTheme);

const Stack = createNativeStackNavigator<RootStackParamList>();
export const Router = () => {
    const linking : LinkingOptions<RootStackParamList> = {
        prefixes: ['https://devicecheck.smartbook-web.dk/'],
        config: {
            initialRouteName: "Home",
            screens: {
                Home: "Home",
                DeviceCheck: 'device-check/:bookId',
            },
        }
    };
    return (
        <PaperProvider theme={CombinedDarkTheme}>
            <SafeAreaProvider>
                <NavigationContainer theme={CombinedDarkTheme} linking={linking}>
                    <Stack.Navigator>
                        <Stack.Screen name="Home" component={HomePage} options={{ title: "Bøger", headerShown: false }} />
                        <Stack.Screen name="DeviceCheck" component={DeviceCheck} options={{ title: "Enhedsunderstøttelse", headerShown: false }} />
                    </Stack.Navigator>
                </NavigationContainer>
            </SafeAreaProvider>
        </PaperProvider>
    )
}