import { useTheme, Text, Card, IconButton } from 'react-native-paper';
import { MainWrapper, ThemedView } from '../components';
import { NativeStackNavigationProp, createNativeStackNavigator } from '@react-navigation/native-stack';
import { BookIds } from '../enums';
import { DeviceCheck } from './devicecheck';
import { useEffect, useState } from 'react';
import '../translation';
import { useTranslation } from 'react-i18next';
export const HomePage = ({ navigation }: { navigation: NativeStackNavigationProp<any> }) => {
    const { t, i18n } = useTranslation();
    const { fonts } = useTheme();
    const [open, setOpen] = useState(false);
    useEffect(() => {
        var lng = getUserLanguage();
        i18n.changeLanguage(localStorage.getItem("language") ?? lng)
    }, [0])
    const getUserLanguage = () => {
        var lng = navigator.language;
        if (lng == "da" || lng == "da-DK")
            return "da";
        return "en";
    }
    const selectLanguage = (locale: string) => {
        i18n.changeLanguage(locale)
        localStorage.setItem("language", locale);
        setOpen(false);
    }
    return (
        <>
            <MainWrapper>
                <Card style={{ margin: 10, height: 30, width: 40 }} contentStyle={{ flex: 1, flexDirection: "row", alignItems: "center" }} elevation={5} onPress={() => { setOpen(!open) }}>
                    <LanguageIcon language={i18n.language} />
                </Card>
                <div style={{ backgroundColor: "#272629" }} hidden={!open}>
                    <Card style={{ margin: 10, height: 30, width: 40 }} contentStyle={{ flex: 1, flexDirection: "row", alignItems: "center" }} elevation={5} onPress={() => { selectLanguage("en") }}>
                        <LanguageIcon language='en' />
                    </Card>
                    <Card style={{ margin: 10, height: 30, width: 40 }} contentStyle={{ flex: 1, flexDirection: "row", alignItems: "center" }} elevation={5} onPress={() => { selectLanguage("da") }}>
                        <LanguageIcon language='da' />
                    </Card>
                </div>
                <ThemedView style={{ padding: 20, flex: 1, flexDirection: "column", overflow: "scroll", overflowX: "hidden", alignItems: "center" }}>
                    <Text style={[{ ...fonts.headlineMedium }, { textAlign: "center", marginBottom: 20 }]}>{t("ChooseBook")}</Text>
                    <Card style={{ margin: 10, height: 296, width: 210 }} elevation={5} onPress={() => { navigation.push("DeviceCheck", { "bookId": BookIds.Firemaster }) }}>
                        <Card.Cover style={{ flexGrow: 1, height: 296, width: 210, backgroundColor: "none" }} source={{ uri: i18n.language == "en" ? require('../../assets/firemaster_en.jpg') : require("../../assets/firemaster.jpg") }} />
                    </Card>
                    <Card style={{ margin: 10, height: 296, width: 210 }} elevation={5} onPress={() => { navigation.push("DeviceCheck", { "bookId": BookIds.ArchmageTower }) }}>
                        <Card.Cover style={{ flexGrow: 1, height: 296, width: 210, backgroundColor: "none" }} source={{ uri: i18n.language == "en" ? require('../../assets/archmage_en.jpg') : require("../../assets/archmage.jpg") }} />
                    </Card>
                    <Card style={{ margin: 10, height: 296, width: 210 }} elevation={5} onPress={() => { navigation.push("DeviceCheck", { "bookId": BookIds.GloomDragon }) }}>
                        <Card.Cover style={{ flexGrow: 1, height: 296, width: 210, backgroundColor: "none" }} source={{ uri: i18n.language == "en" ? require('../../assets/gloomdragon_en.jpg') : require("../../assets/gloomdragon.jpg") }} />
                    </Card>
                    <Card style={{ margin: 10, height: 296, width: 210 }} elevation={5} onPress={() => { navigation.push("DeviceCheck", { "bookId": BookIds.WonderSword }) }}>
                        <Card.Cover style={{ flexGrow: 1, height: 296, width: 210, backgroundColor: "none" }} source={{ uri: i18n.language == "en" ? require('../../assets/wondersword_en.jpg') : require("../../assets/wondersword.jpg") }} />
                    </Card>
                    <Card style={{ margin: 10, height: 296, width: 210 }} elevation={5} onPress={() => { navigation.push("DeviceCheck", { "bookId": BookIds.Outtatown }) }}>
                        <Card.Cover style={{ flexGrow: 1, height: 296, width: 210, backgroundColor: "none" }} source={{ uri: i18n.language == "en" ? require('../../assets/outtatown_en.jpg') : require("../../assets/outtatown.jpg") }} />
                    </Card>
                    <Card style={{ margin: 10, height: 296, width: 210 }} elevation={5} onPress={() => { navigation.push("DeviceCheck", { "bookId": BookIds.ColouringBook }) }}>
                        <Card.Cover style={{ flexGrow: 1, height: 296, width: 210, backgroundColor: "none" }} source={{ uri: i18n.language == "en" ? require('../../assets/colouringbook_en.jpg') : require("../../assets/colouringbook.jpg") }} />
                    </Card>
                </ThemedView>
            </MainWrapper>
        </>
    )
}
const LanguageIcon = ({ language }: { language: string }) => {
    if (language == "da") {
        return (
            <>
                <Card.Cover style={{ flexGrow: 1, height: 30, width: 40, marginEnd: 10, backgroundColor: "none" }} source={{ uri: require("../../assets/denmark-flag-icon.png") }} />
                <Text>Dansk</Text>
            </>
        )
    } else {
        return (
            <>
                <Card.Cover style={{ flexGrow: 1, height: 30, width: 40, marginEnd: 10, backgroundColor: "none" }} source={{ uri: require("../../assets/united-kingdom-flag-icon.png") }} />
                <Text>English</Text>
            </>
        )
    }
}